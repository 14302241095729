export default {
    init() {
        const eventCategories = document.querySelectorAll(".event-category");
        
        eventCategories.forEach(function(category){
            category.addEventListener("click", function () {
                // close all other categories when clicking one category
                document.querySelector(".event-content.active").classList.remove("active");
                document.querySelector(".event-category.active").classList.remove("active");
                // open details of selected category
                const target = category.dataset.target;
                const eventContent = document.querySelector(".event-content-" + target);
                eventContent.classList.add("active");
                category.classList.add("active");
            })
        });


    },
}
